import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

const TiendaProduct = ({ item }) => {

    const [img, setImg] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://api.mercadolibre.com/items/' + item.id);
                setImg(response.data.pictures[0].secure_url);
            } catch (error) {
                console.error('Error fetching img:', error);
            }
        };

        fetchData();
    }, []);

    return (
        <Link to={item.permalink} target="_blanc" id="tienda-product" className="d-flex b-glass-1 text-decoration-none black-1">
            {/*<h5 id="tienda-product-hover-h5">Click para ver en Mercado Libre</h5>*/}
            <div id="product-img-container" className="d-flex align-items-center justify-content-center">
                <img className="w-100" src={img} alt="img" />
            </div>
            <div id="product-desc-container" className="d-flex flex-column">
                <h4 className="m-0">{item.title.replace(/-/g, '\u2011')}</h4>
                <h5 className="m-0">Condición: {item.condition === 'used' ? 'Usado' : 'Nuevo'}</h5>
                <h3>{Intl.NumberFormat('es-ES').format(item.price)}$</h3>
            </div>
        </Link>
    );
}

export default TiendaProduct;
