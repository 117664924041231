import { configureStore } from '@reduxjs/toolkit';
import goToReducer from './goToSlice';
import productsReducer from './productsSlice';

export default configureStore({
  reducer: {
    goTo: goToReducer,
    products: productsReducer,
  },
});
