import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setGoTo } from '../redux/goToSlice';

import cel from '../assets/img/celular-home.png'
import cel1 from '../assets/img/celular-home-1.png'
import cel2 from '../assets/img/celular-home-2.png'
import cel3 from '../assets/img/celular-home-3.png'
import cel4 from '../assets/img/celular-home-4.png'

import foto1 from '../assets/img/user-1.webp';
import foto2 from '../assets/img/user-2.webp';
import foto3 from '../assets/img/user-3.webp';
import foto4 from '../assets/img/user-4.webp';

import Nav from '../components/Nav.jsx';
import Footer from '../components/Footer.jsx';
import Review from '../components/Review.jsx';

const services = [
    { icon: 'bi bi-tools gradient-1R', text: 'Reparación' },
    { icon: 'bi bi-battery-charging gradient-1R', text: 'Cambio de batería' },
    { icon: 'bi bi-phone gradient-1R', text: 'Cambio de pantalla' },
    { icon: 'bi bi-phone-flip gradient-1R', text: 'Migración de datos a<br/>un teléfono nuevo' },
    { icon: 'bi bi-brush gradient-1R', text: 'Limpieza' },
    { icon: 'bi bi-pc-display gradient-1R', text: 'Armado de PC' },
    { icon: 'bi bi-hdd gradient-1R', text: 'Migración de HDD a SDD' },
    { icon: 'bi bi-windows gradient-1R', text: 'Formateo y reinstalación<br/>de Windows 7 y 10' },
    { icon: 'bi bi-cpu gradient-1R', text: 'Cambio de pasta térmica' },
    { icon: 'bi bi-body-text gradient-1R', text: 'Rescate de datos de<br/>PC que no enciende' }
];

const Home = () => {
    
    const reviewsRef = useRef(null);
    const serviciosRef = useRef(null);
    const contactoRef = useRef(null);

    const [backgroundKey, setBackgroundKey] = useState(0);

    const dispatch = useDispatch();
    const goTo = useSelector((state) => state.goTo.value);
    
    useEffect(() => {
        const handleScroll = (location) => {

            const scrollOptions = {
                behavior: 'smooth',
                block: 'center',
                inline: 'nearest'
            };

            const scrollTo = (options) => window.scrollTo(options);

            const locations = {
                servicios: () => scrollTo({top: serviciosRef.current.getBoundingClientRect().top + window.scrollY - (window.innerHeight - serviciosRef.current.getBoundingClientRect().height) / 2, ...scrollOptions}),
                serviciosNormal: () => scrollTo({top: serviciosRef.current.getBoundingClientRect().top + window.scrollY}),
                contacto: () => scrollTo({top: contactoRef.current.getBoundingClientRect().top + window.scrollY - (window.innerHeight - contactoRef.current.getBoundingClientRect().height) / 2, ...scrollOptions}),
                inicio: () => window.scrollTo(0, 0)
            };

            const refMap = {
                servicios: serviciosRef,
                contacto: contactoRef,
                inicio: null
            };

            const scrollToLocation = locations[location];
            if (scrollToLocation) scrollToLocation();
            
            const ref = refMap[location];
        };

        if (goTo) {
            handleScroll(goTo);
            dispatch(setGoTo(null));
        }
    });

    useEffect(() => {
        const handleResize = () => {
            const currentWidth = window.innerWidth;
            const isWide = currentWidth > 1200;
            const wasWide = document.body.classList.contains('wide');

            if ((isWide && !wasWide) || (!isWide && wasWide)) {
                setBackgroundKey(prevKey => prevKey + 1);
                document.body.classList.toggle('wide', isWide);
                document.body.classList.toggle('narrow', !isWide);
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const calculateDelay = (index) => {
            return (index % 4) * 0.1;
        };
        const calculateDelay2 = (index, screenWidth) => {
            if (screenWidth > 1000) {
                return ((index - 1) % 5) * 0.1 + 0.2;
            } else if (screenWidth > 600) { 
                return ((index - 1) % 3) * 0.1 + 0.2;
            } else {
                return ((index - 1) % 2) * 0.1 + 0.2;
            }
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    const index = entry.target.dataset.index;
                    const delay = calculateDelay(index);
                    entry.target.style.animationDelay = `${delay}s`;
                    entry.target.classList.add('fade-in-scale');
                }
            });
        }, { threshold: 0.1 });
        const observer2 = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    const index = entry.target.dataset.index;
                    const screenWidth = window.innerWidth;
                    const delay = calculateDelay2(index, screenWidth);
                    entry.target.style.animationDelay = `${delay}s`;
                    entry.target.classList.add('fade-in-up');
                }
            });
        }, { threshold: 0.1 });

        const items1 = reviewsRef.current.querySelectorAll('#review-card');
        items1.forEach((item, index) => {
            item.dataset.index = index;
            observer.observe(item);
        });
        const items2 = serviciosRef.current.querySelectorAll('.home-section-2-grid-item, h2');
        items2.forEach((item, index) => {
            item.dataset.index = index;
            observer2.observe(item);
        });

        return () => {
            items2.forEach(item => observer2.unobserve(item));
        };
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            const background = document.getElementById('home-section-1');
            const background2 = document.getElementById('home-section-3');
    
            if (background && background2) {
                const section1 = background.getBoundingClientRect();
                const sectionBottom = section1.bottom;
                const section3 = background2.getBoundingClientRect();
                const sectionBottom2 = section3.bottom;
    
                if (window.innerWidth > 1200) {
                    background.style.backgroundPositionY = `calc(${-((scrollPosition - sectionBottom) * 0.2)}px)`;
                    background2.style.backgroundPositionY = `${-((scrollPosition - sectionBottom2) * 0.1)}px`;
                }
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div id="home" className="overflow-hidden">
            <Nav />
            <div id="home-banner" className="b-gradient-3L vh-100 w-100 d-flex align-items-center justify-content-center">
                <div id="home-container-farafix" className="d-flex flex-column align-items-center justify-content-center">
                    <h1 className="gradient-1B m-0">FARAFIX</h1>
                    <h4 id="home-subtitle" className="white-2 text-center no-wrap">Reparación de celulares, PCs, notebooks y PS4</h4>
                    <div id="home-location" className="d-flex align-items-center mt-4 mt-md-5">
                        <i className="gradient-1B bi bi-geo-alt-fill me-1"></i>
                        <h4 className="gradient-1B mt-1">Vicente López, Buenos Aires</h4>
                    </div>
                </div>
                <div id="home-container-celular" className="hide-800 align-items-center">
                    <img id="home-celular-1" className="home-celular-img" src={cel1} alt="1" />
                    <img id="home-celular-2" className="home-celular-img" src={cel2} alt="2" />
                    <img id="home-celular-3" className="home-celular-img" src={cel3} alt="3" />
                    <img id="home-celular-4" className="home-celular-img" src={cel4} alt="4" />
                </div>
                <div id="home-container-celular-mobile" className="show-600">
                    <img id="home-container-celular-mobile-img" className="h-100" src={cel} alt="1"></img>
                </div>
            </div>
            <div className="show-650 w-100 pt-1 b-gradient-2L"></div>
            <div id="home-section-1" ref={reviewsRef} className="d-flex flex-column align-items-center justify-content-center">
                <div id="home-section-1-title" className="d-flex flex-column align-items-center">
                    <h4 className="white-1">Servicio rápido y confiable, con atención personalizada y profesional.</h4>
                    <h4 className="white-1 hide-650">7 años de experiencia y más de 1000 clientes satisfechos.</h4>
                </div>
                <div className="d-flex hide-650">
                    <Review foto={foto1} nombre={'Federico García'} estrellas={true} comentario={'Muy buena atención. Mi celular tenía la pantalla rota y quedó como nuevo.'} />
                    <Review foto={foto2} nombre={'Juan M. Gómez S.'} estrellas={true} comentario={'Llevé 2 computadoras para arreglar y estaban listas al día siguiente.'} />
                    <Review hide={'hide-1000'} foto={foto3} nombre={'José Wang'} estrellas={false} comentario={'Muy bien todo.'} />
                    <Review foto={foto4} nombre={'Ramiro Gallardo'} estrellas={true} comentario={'Excelente trabajo. Muy rápido y a buen precio'} />
                </div>
                <div className="show-650 align-items-end mt-2 mb-4">
                    <h2 className="blue-0">7</h2>
                    <div className="mx-1"></div>
                    <h3 className="gradient-0R">años de experiencia</h3>
                </div>
                <div className="show-650 align-items-end ">
                    <h2 className="blue-0">+1000</h2>
                    <div className="mx-1"></div>
                    <h3 className="gradient-0R">clientes satisfechos</h3>
                </div>
            </div>
            <div className="show-650 w-100 pt-1 b-gradient-2R"></div>
            <div id="home-section-2" ref={serviciosRef} className="b-gradient-3R d-flex flex-column align-items-center justify-content-center white-2">
                <h2 className="gradient-0R">Servicios</h2>
                <div id="home-section-2-grid" className="d-flex justify-content-center mb-3">
                    {services.map((service, index) => (
                        <div key={index} className="home-section-2-grid-item d-flex flex-column align-items-center">
                            <div className="home-section-2-icon-container d-flex align-items-center justify-content-center">
                                <i className={service.icon}></i>
                            </div>
                            <h5 className="m-0 pt-1 text-center gradient-0R" dangerouslySetInnerHTML={{ __html: service.text }}></h5>
                        </div>
                    ))}
                </div>
            </div>
            <div className="show-650 w-100 pt-1 b-gradient-2R"></div>
            <div id="home-section-3" key={`section-3-${backgroundKey}`} ref={contactoRef} className="w-100 d-flex align-items-center justify-content-center">
                <div id="home-section-3-container-1" className="home-section-3-container h-60 d-flex align-items-center justify-content-end pe-2 pe-sm-3 pe-md-4 pe-xl-5">
                    <h2 className="white-1">Contacto</h2>
                </div>
                <div id="home-section-3-container-2" className="home-section-3-container h-60 text-center d-flex  flex-column justify-content-center white-1 ps-2 ps-sm-3 ps-md-4 ps-xl-5">
                    <h5 className="no-wrap mb-2 mb-xl-3">Ante cualquier duda, consulta o encargo,<br/>contactate por Whatsapp:</h5>
                    <Link to='https://wa.me/541153235696?texto=Hola%20me%20contacto%20para%20consultarte%20acerca%20de%20:' target="_blank" className="text-decoration-none mb-2 mb-lg-4 mb-xl-5"><h3 className="gradient-0R no-wrap hover-white-1">+54 11 5323-5696</h3></Link>
                    <h5 className="no-wrap mb-1 mb-lg-2">También visitá nuestr<span className="show-350">o</span><span className="hide-350">a página de</span> Instagram:</h5>
                    <Link to='https://www.instagram.com/ifarafix/' target="_blank" className="text-decoration-none"><h3 className="gradient-0L hover-white-1">@iFaraFix</h3></Link>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Home;