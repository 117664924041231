import { Routes, Route, BrowserRouter } from "react-router-dom";
import React, { useEffect, useState } from 'react';

import Home from './views/Home.jsx';
import Tienda from './views/Tienda.jsx';

import './assets/css/styles.css';

function App() {

  const [isNavHidden, setIsNavHidden] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);

  useEffect(() => {
    const handleScroll = () => {
        const currentScrollPos = window.pageYOffset;
        if (prevScrollPos + 0.5 > currentScrollPos) {
          if (isNavHidden) {
            navBar.style.transform = 'translateY(0)';
            setIsNavHidden(false);
          }
        } else {
          if (!isNavHidden) {
            navBar.style.transform = 'translateY(-100%)';
            setIsNavHidden(true);
          }
        }
        setPrevScrollPos(currentScrollPos);
    };
  
    const navBar = document.querySelector('nav');
    window.addEventListener('scroll', handleScroll);
    
    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
  }, [isNavHidden, prevScrollPos,]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/tienda' element={<Tienda />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;