import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export const productsSlice = createSlice({
  name: 'products',
  initialState: { items: [] },
  reducers: {
    setProducts: (state, action) => {
      state.items = action.payload;
    },
  },
});

export const { setProducts } = productsSlice.actions;

export const fetchProducts = () => async (dispatch) => {
  try {
    const response = await axios.get('https://api.mercadolibre.com/sites/MLA/search?nickname=ifarafix');
    dispatch(setProducts(response.data.results));
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

export default productsSlice.reducer;