import { createSlice } from '@reduxjs/toolkit';

export const goToSlice = createSlice({
  name: 'goTo',
  initialState: { value: null },
  reducers: {
    setGoTo: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setGoTo } = goToSlice.actions;

export default goToSlice.reducer;
