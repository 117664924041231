import React, { useState, useEffect } from 'react';

const Review = ({ hide, foto, nombre, estrellas, comentario }) => {
    return (
        <div id="review-card" className={`${hide} d-flex flex-column align-items-center justify-content-between b-white-2`}>
            <div className="d-flex flex-column">
                <div id="review-card-photo-name" className="d-flex align-items-center">
                    <img id="review-card-photo" src={foto} alt="foto" />
                    <p id="review-card-name" className="black-2 m-0">{nombre}</p>
                </div>
                <div id="review-card-comment">
                    <p className="black-1">"{comentario}"</p>
                </div>
            </div>
            <div id="review-card-stars" className="d-flex">
                <i className="bi bi-star-fill me-1"></i>
                <i className="bi bi-star-fill me-1"></i>
                <i className="bi bi-star-fill me-1"></i>
                <i className="bi bi-star-fill me-1"></i>
                {estrellas ? <i className="bi bi-star-fill"></i> : <i className="bi bi-star-half"></i>}
            </div>
        </div>
    );
}

export default Review;
