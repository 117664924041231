import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setGoTo } from '../redux/goToSlice';

const Nav = () => {

    const dispatch = useDispatch();

    return (
        <nav className="d-flex align-items-center justify-content-center w-100">
            <Link className="text-decoration-none" to="/" onClick={() => dispatch(setGoTo('inicio'))}><h6 className="m-0 py-2 px-2 px-sm-3 mx-1 mx-sm-0">INICIO</h6></Link>
            <Link 
                className="text-decoration-none" 
                to="/" 
                onClick={() => {
                    (window.innerWidth <= 1000 && window.innerHeight <= 540) || (window.innerWidth <= 600 && window.innerHeight <= 775) 
                    ? dispatch(setGoTo('serviciosNormal')) 
                    : dispatch(setGoTo('servicios'))
                }}
            >
                <h6 className="m-0 py-2 px-2 px-sm-3 mx-1 mx-sm-0">SERVICIOS</h6>
            </Link>
            <Link className="text-decoration-none" to="/" onClick={() => dispatch(setGoTo('contacto'))}><h6 className="m-0 py-2 px-2 px-sm-3 mx-1 mx-sm-0">CONTACTO</h6></Link>
            <Link className="text-decoration-none" to="/tienda" onClick={() => (window.scrollTo(0, 0), dispatch(setGoTo('inicio')))}><h6 className="m-0 py-2 px-2 px-sm-3 mx-1 mx-sm-0">TIENDA</h6></Link>
        </nav>
    );
}

export default Nav;
