import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setGoTo } from '../redux/goToSlice';

const Footer = () => {

    const dispatch = useDispatch();
    
    return (
        <footer className="b-gradient-3L d-flex-flex-column">
            <div className="w-100 p-1 b-gradient-2L"></div>
            <div className="d-flex justify-content-center py-3 py-md-4 py-lg-5 mt-2 mt-lg-3">
                <h3 className="w-35 gradient-0L text-center">FARAFIX</h3>
                <div className="w-25 d-flex flex-column align-items-center">
                    <div id="footer-buttons" className="d-flex flex-column">
                        <Link className="text-decoration-none" to="/" onClick={() => dispatch(setGoTo('inicio'))}><h6 className="white-2">Inicio</h6></Link>
                        <Link 
                            className="text-decoration-none" 
                            to="/" 
                            onClick={() => {
                                (window.innerWidth <= 1000 && window.innerHeight <= 540) || (window.innerWidth <= 600 && window.innerHeight <= 775) 
                                ? dispatch(setGoTo('serviciosNormal')) 
                                : dispatch(setGoTo('servicios'))
                            }}
                        >
                            <h6 className="white-2">Servicios</h6>
                        </Link>
                        <Link className="text-decoration-none" to="/" onClick={() => dispatch(setGoTo('contacto'))}><h6 className="white-2">Contacto</h6></Link>
                        <Link className="text-decoration-none" to="/tienda"  onClick={() => (window.scrollTo(0, 0), dispatch(setGoTo('inicio')))}><h6 className="white-2">Tienda</h6></Link>
                    </div>
                </div>
                <div className="w-35 d-flex flex-column align-items-center">
                    <h6 id="footer-vte" className="white-2">Vicente López, Buenos Aires</h6>
                    <h6 className="white-2">+54 11 5323-5696</h6>
                    <h6 className="white-2"></h6>
                </div>
            </div>
            <div id="all-r-r" className="d-flex flex-column align-items-center pb-1">
                <p className="glass-0 m-0">Todos los derechos reservados | FaraFix®</p>
                <p className="glass-0 m-0">Hecho por Ivo Gonsé</p>
            </div>
        </footer>
    );
}

export default Footer;
