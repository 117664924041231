import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setGoTo } from '../redux/goToSlice';
import { fetchProducts } from '../redux/productsSlice.js';

import Nav from '../components/Nav.jsx'
import Footer from '../components/Footer.jsx'

import TiendaProduct from '../components/TiendaProduct.jsx'

const Tienda = () => {

    const dispatch = useDispatch();
    const goTo = useSelector((state) => state.goTo.value);
    const products = useSelector((state) => state.products.items);
    const [checked, setChecked] = useState(false);
    
    useEffect(() => {
        fetchAsync();
    }, [dispatch]);

    const fetchAsync = async () => {
        await dispatch(fetchProducts());
        setChecked(true);
    }

    useEffect(() => {
        if (goTo) {
            window.scrollTo(0, 0);
            dispatch(setGoTo(null));
        }
    });

    return (
        <div id="tienda" className="b-gradient-3L">
            <Nav />
            <div id="tienda-banner" className="d-flex justify-content-center align-items-center">
                <h2 className="white-2 fst-italic">Tienda</h2>
            </div>
            { products.length > 0 &&
                <div id="tienda-productos" className="b-gradient-0L d-flex flex-column align-items-center">
                    <h5 id="tienda-viendo" className="black-1 m-0">Viendo productos: {products.length} de {products.length}</h5>
                    {products.map((item) => (
                        <TiendaProduct item={item} />
                    ))}
                </div>
            }
            { products.length > 0 &&
                <div id="tienda-indicador-pagina" className="w-100 b-gradient-0L d-flex align-items-center justify-content-center">
                    <i className="bi bi-caret-left gradient-1B mx-3"></i>
                    <h4 className="m-0 black-1 cursor-default">Página 1</h4>
                    <button className="border-none b-transparent mx-2 cursor-default"><i className="bi bi-caret-right gradient-1B"></i></button>
                </div>
            }
            { checked && products.length === 0 &&
                <div id="tienda-productos" className="b-gradient-0L d-flex flex-column align-items-center justify-content-center">
                    <h3 className="w-80 body gray-0 text-center">No hay productos en la tienda en este momento :(</h3>
                </div>
            }
            { !checked && products.length === 0 &&
                <div id="tienda-productos" className="b-gradient-0L d-flex flex-column align-items-center justify-content-center">
                    <div className="py-5"></div>
                </div>
            } 
            <Footer />
        </div>
    );
}

export default Tienda;
